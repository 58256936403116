import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import { QuoteOrderTableColumns } from "../settings/quote-order-columns";
import { QuoteConstants } from "../settings/quote-constants";
import { SelectionMode } from "@fluentui/react/lib/Utilities";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { getTimezone, redirectUrl } from "../../common/actions/general-actions";
import moment from "moment";
import {
  TooltipHost,
  DetailsList,
  DetailsRow,
  IconButton,
  ConstrainMode,
  css,
} from "@fluentui/react";
import { OpenInNewTabIcon } from "@fluentui/react-icons";
import {
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
} from "../actions/quote-action";
import { cloneDeep } from "lodash";
import { useRef } from "react";
import CustomStyles from "../../common/helpers/custom-styles";
import _ from "lodash";

/**
Project: Partner Portal (c)
Title: Subscriber Number Table Columns  
Description: Settings related to Subscriber Number list page table column 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/

const QuoteServiceTable = ({
  intlNamespace,
  quoteEstimatorInfo,
  handleQuoteEdit,
  handleQuoteDelete,
  isEditable,
  quoteType,
  isModalEnabled,
  history,
  specialBidNumber,
  pageType,
  websiteTemplate,
  existingQuoteProductDetails,
  quotePreview,
}) => {
  const [serviceRecords, setServiceRecords] = useState(
    quoteEstimatorInfo?.serviceAndConnectionRecords ?? []
  );
  const [sourceProduct, setSourceProduct] = useState("");
  const itemRef = useRef();

  useEffect(() => {
    let sourceProductName = "";
    let productRecords =
      existingQuoteProductDetails?.length > 0
        ? existingQuoteProductDetails
        : quoteEstimatorInfo?.serviceAndConnectionRecords?.length > 0
        ? quoteEstimatorInfo?.serviceAndConnectionRecords
        : [];
    if (productRecords && productRecords?.length > 0)
      productRecords = _.cloneDeep(productRecords);
    if (quotePreview)
      productRecords = productRecords.map((rec) => {
        let totalPrice =
          rec?.pricing?.length > 0 &&
          rec?.pricing?.every((price) => price.sellingPrice <= 0);
        if (!totalPrice)
          rec.pricing = rec.pricing.filter((char) => char.sellingPrice > 0);
        return rec;
      });
    if (
      quoteType?.toLowerCase().trim() ===
      OperatorConnectConstants.QUOTE.QUOTE_TYPE_LIST[1].key
        ?.toLowerCase()
        .trim()
    ) {
      let filteredServiceRecords = [];

      productRecords.forEach((rec) => {
        if (rec?.bundleData) {
          if (
            rec?.bundleData.itemType?.toLowerCase().trim() !==
            OperatorConnectConstants.QUOTE.ITEM_TYPE.SOURCE?.toLowerCase().trim()
          ) {
            filteredServiceRecords.push(rec);
          }
          if (
            rec?.bundleData?.itemType?.toLowerCase().trim() ===
            OperatorConnectConstants.QUOTE.ITEM_TYPE.SOURCE?.toLowerCase().trim()
          ) {
            sourceProductName = `${rec.productName} (${rec.productCode})`;
          }
        }
      });
      if (sourceProduct === "") {
        setSourceProduct(sourceProductName);
      }
      setServiceRecords(filteredServiceRecords);
    } else {
      setSourceProduct("");
      setServiceRecords(productRecords);
    }
  }, [
    quoteType,
    quoteEstimatorInfo?.serviceAndConnectionRecords,
    sourceProduct,
    existingQuoteProductDetails,
  ]);

  const handleQuoteSpecialBidData = (
    item,
    pricing,
    chargeType,
    pricingCode
  ) => {
    if (item && pricing !== null && chargeType) {
      let specialBidProducts = cloneDeep(
        quoteEstimatorInfo?.specialBidRecord?.products ?? []
      );
      let priceValue = pricing;
      let availableQuantity = 0;
      if (specialBidProducts?.length > 0) {
        specialBidProducts.forEach((product) => {
          if (
            item?.bundleData?.itemType?.toLowerCase().trim() ===
            OperatorConnectConstants.QUOTE.ITEM_TYPE?.MOVE?.toLowerCase().trim()
          ) {
            if (
              product.productId === item.productId &&
              product.planName === item.connection &&
              product.availabilityName === item.selectedCountry
            ) {
              if (product?.pricing?.length > 0) {
                product?.pricing.forEach((rec) => {
                  if (
                    rec.chargeType === chargeType &&
                    rec.pricingCode === pricingCode
                  ) {
                    availableQuantity =
                      parseFloat(product.quantity) -
                      parseFloat(product.consumedQuantity);

                    priceValue = rec.offerPrice;
                  }
                });
              }
            }
          } else {
            if (
              product.productId === item.productId &&
              ((item.communicationPlatform &&
                (product.communicationPlatform ===
                  item.communicationPlatform?.platformName ||
                  item.communicationPlatform === "No Platform")) ||
                item.communicationPlatform === null) &&
              product.planName === item.connection &&
              product.availabilityName === item.selectedCountry
            ) {
              if (product?.pricing?.length > 0) {
                product?.pricing.forEach((rec) => {
                  if (
                    rec.chargeType === chargeType &&
                    rec.pricingCode === pricingCode
                  ) {
                    availableQuantity =
                      parseFloat(product.quantity) -
                      parseFloat(product.consumedQuantity);

                    priceValue = rec.offerPrice;
                  }
                });
              }
            }
          }
        });
        if (availableQuantity >= parseFloat(item?.quantity ?? "0")) {
          return parseFloat(item?.quantity ?? "0") * parseFloat(priceValue);
        } else {
          return (
            availableQuantity * parseFloat(priceValue) +
            (parseFloat(item?.quantity ?? "0") - availableQuantity) *
              parseFloat(pricing)
          );
        }
      } else {
        return parseFloat(item?.quantity ?? "0") * parseFloat(priceValue);
      }
    }
  };

  const handleCalculateDiscount = (amount, discount) => {
    let discountedAmount = 0;
    let disc = discount ? discount : 0;
    let amt = amount ? amount : 0;
    discountedAmount = amount - (amt * disc) / 100;
    return parseFloat(Number(discountedAmount));
  };
  const handleTermLinkClick = (termLink) => {
    if (termLink) {
      redirectUrl(termLink);
    }
  };

  const handleItemQuoteEdit = async (e, item) => {
    await handleQuoteEdit(e, item);
  };

  const renderDetailsListFooter = (detailsFooterProps) => {
    return (
      <>
        <DetailsRow
          {...detailsFooterProps}
          columns={detailsFooterProps.columns}
          className="quote-view-footer"
          item={{}}
          itemIndex={-1}
          groupNestingDepth={detailsFooterProps.groupNestingDepth}
          selectionMode={SelectionMode.none}
          selection={detailsFooterProps.selection}
          onRenderItemColumn={(item, index, column) =>
            renderSubTotalDetailsFooterItemColumn(item, index, column)
          }
        />
      </>
    );
  };

  const renderSubTotalDetailsFooterItemColumn = (item, index, column) => {
    switch (column.key) {
      case "partnerCostUnitPrice":
        return <span className="text-ff-semibold">{partnerTotalCost()}</span>;
      case "customerPriceUnitPrice":
        return <span className="text-ff-semibold">{customerTotalprice()}</span>;
      case "quantity":
        return (
          <div className="p-r-15 quote-footer-text">
            <span className="text-ff-semibold">
              <span>{IntlUtil.getText(intlNamespace, "content.total")}</span>
            </span>
            &nbsp;
            <span className="">
              {`(${IntlUtil.getText(intlNamespace, "content.taxNotIncluded")})`}
            </span>
          </div>
        );
      default:
        return "";
    }
  };

  const renderChargeTypeLabel = (chargeType) => {
    let chargeTypeValue = "";
    QuoteConstants.CHARGE_TYPE_LIST.forEach((charge) => {
      if (
        chargeType?.toLowerCase().trim() === charge.key?.toLowerCase().trim()
      ) {
        chargeTypeValue = charge.text;
      }
    });
    return chargeTypeValue;
  };

  const handleConnectionName = (planId, planTypes) => {
    let planName = null;
    if (planTypes && planTypes.length > 0) {
      planTypes.forEach((rec) => {
        if (rec.planId === planId) {
          planName = rec.planName;
        }
      });
    }
    return planName;
  };

  const handleQuoteItemDelete = async (item) => {
    await handleQuoteDelete(item);
  };
  const partnerTotalCost = () => {
    let partnerCost = 0;
    let currencySymbol = "";
    let records = cloneDeep(serviceRecords);
    if (records?.length > 0) {
      records.forEach((rec) => {
        currencySymbol = rec.currencySymbol;
        if (rec.pricing?.length > 0) {
          rec.pricing.forEach((price) => {
            if (price.buyingPrice !== null) {
              partnerCost =
                partnerCost +
                handleCalculateDiscount(
                  handleQuoteSpecialBidData(
                    rec,
                    parseFloat(price.buyingPrice).toFixed(2),
                    price.chargeType,
                    price.pricingCode
                  ),
                  rec.discount
                );
            }
          });
        }
      });
    }
    return (
      currencySymbol +
      parseFloat(partnerCost)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };
  const customerTotalprice = () => {
    let customerPrice = 0;
    let currencySymbol = "";

    let records = cloneDeep(serviceRecords);
    if (records?.length > 0) {
      records.forEach((rec) => {
        currencySymbol = rec.currencySymbol;
        if (rec.pricing?.length > 0) {
          rec.pricing.forEach((price) => {
            if (price.sellingPrice !== null) {
              customerPrice =
                customerPrice +
                parseFloat(rec?.quantity ?? "0").toFixed(2) *
                  parseFloat(price?.sellingPrice).toFixed(2);
            }
          });
        }
      });
    }
    return (
      currencySymbol +
      parseFloat(customerPrice)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };
  const customerNRCprice = () => {
    let customerPrice = 0;
    let currencySymbol = "";

    let records = cloneDeep(serviceRecords);
    if (records?.length > 0) {
      records.forEach((rec) => {
        currencySymbol = rec.currencySymbol;
        if (rec.pricing?.length > 0) {
          rec.pricing.forEach((price) => {
            if (price.sellingPrice !== null) {
              if (
                price.chargeType?.toLowerCase().trim() ===
                QuoteConstants.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
              ) {
                customerPrice =
                  customerPrice +
                  parseFloat(rec?.quantity ?? "0").toFixed(2) *
                    parseFloat(price?.sellingPrice).toFixed(2);
              }
            }
          });
        }
      });
    }
    return (
      currencySymbol +
      parseFloat(customerPrice)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };
  const customerMRCprice = () => {
    let customerPrice = 0;
    let currencySymbol = "";

    let records = cloneDeep(serviceRecords);
    if (records?.length > 0) {
      records.forEach((rec) => {
        currencySymbol = rec.currencySymbol;
        if (rec.pricing?.length > 0) {
          rec.pricing.forEach((price) => {
            if (price.sellingPrice !== null) {
              if (
                price.chargeType?.toLowerCase().trim() ===
                QuoteConstants.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
              ) {
                customerPrice =
                  customerPrice +
                  parseFloat(rec?.quantity ?? "0").toFixed(2) *
                    parseFloat(price?.sellingPrice).toFixed(2);
              }
            }
          });
        }
      });
    }
    return (
      currencySymbol +
      parseFloat(customerPrice)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };
  const renderRow = (props) => {
    return <DetailsRow {...props} componentRef={itemRef} />;
  };
  const handleProductItemRender = (item, index, column) => {
    if (
      item?.fulfillRequestDateError === "required" ||
      item?.fulfillRequestDateError === "expired" ||
      item?.serviceAddressError === "required" ||
      item?.duplicateRecords?.length > 0
    ) {
      if (itemRef.current) {
        itemRef.current.focus();
      }
    }
    switch (column.key) {
      case "actions":
        return (
          <div className={"page-frame-table-item-actions"}>
            <TooltipHost
              hostClassName={css("m-0 p-0")}
              content={IntlUtil.getText(intlNamespace, "content.editItem")}
            >
              <IconButton
                iconProps={{ iconName: "Edit" }}
                onClick={(e) => {
                  handleItemQuoteEdit(e, item);
                }}
                className={
                  CustomStyles.getStyle(
                    websiteTemplate?.templateCode?.toLowerCase().trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "icon-button-secondary"
                      : "icon-button-primary",
                    websiteTemplate
                  ) + " quote-estimator-icon-button"
                }
              />
            </TooltipHost>
            <TooltipHost
              hostClassName={css("m-0 p-0")}
              content={IntlUtil.getText(intlNamespace, "content.deleteItem")}
            >
              <IconButton
                onClick={() => handleQuoteItemDelete(item)}
                iconProps={{ iconName: "Delete" }}
                className={
                  CustomStyles.getStyle(
                    websiteTemplate?.templateCode?.toLowerCase().trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "icon-button-secondary"
                      : "icon-button-primary",
                    websiteTemplate
                  ) + " quote-estimator-icon-button"
                }
              />
            </TooltipHost>
          </div>
        );
      case "item":
        let timeline = null;
        let timelineRecord = null;
        if (item.planTypes && item.planTypes.length > 0) {
          if (
            quoteType?.toLowerCase().trim() ===
            OperatorConnectConstants.QUOTE.QUOTE_TYPE_LIST[1].key
              ?.toLowerCase()
              .trim()
          ) {
            item.planTypes.forEach((rec) => {
              if (
                rec?.planName?.toLowerCase().trim() ===
                item.connection?.toLowerCase().trim()
              ) {
                if (rec.timeLines && rec.timeLines.length > 0) {
                  rec.timeLines.forEach((timeline) => {
                    if (timeline.condition === "gt") {
                      if (parseInt(item.quantity) > parseInt(timeline.value)) {
                        timelineRecord = timeline;
                      }
                    } else if (timeline.condition === "le") {
                      if (parseInt(item.quantity) <= parseInt(timeline.value)) {
                        timelineRecord = timeline;
                      }
                    }
                  });
                }
              }
            });
          } else {
            item.planTypes.forEach((rec) => {
              if (rec.timeLines && rec.timeLines.length > 0) {
                rec.timeLines.forEach((timeline) => {
                  if (timeline.condition === "gt") {
                    if (parseInt(item.quantity) > parseInt(timeline.value)) {
                      timelineRecord = timeline;
                    }
                  } else if (timeline.condition === "le") {
                    if (parseInt(item.quantity) <= parseInt(timeline.value)) {
                      timelineRecord = timeline;
                    }
                  }
                });
              }
            });
          }
        }
        if (timelineRecord) {
          let minimumDays = parseInt(timelineRecord?.minimumDays);
          let maximumDays = parseInt(timelineRecord?.maximumDays);
          let diff = maximumDays - minimumDays;
          maximumDays = minimumDays + diff;
          timeline = {
            minimumDays: minimumDays,
            maximumDays: maximumDays,
          };
        }
        let timezone = null;
        if (
          item.fulfillRequestDate !== undefined &&
          item.fulfillRequestDate !== null
        ) {
          if (
            OperatorConnectConstants.QUOTE.FOC_COUNTRIES.includes(
              item?.availabilityName
            )
          ) {
            timezone = getTimezone(
              moment(item.fulfillRequestDate).format("YYYY-MM-DD"),
              moment(item.fulfillRequestDate).format("HH:mm"),
              item.availabilityName
            );
          }
        }

        return (
          <>
            <div className="">
              <div className="quote-text-wrapper">
                <span
                  className={
                    "font-weight-600 " +
                    CustomStyles.getStyle(
                      websiteTemplate?.templateCode?.toLowerCase().trim() !==
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                        ? "text-fc-primary"
                        : "text-fc-secondary",
                      websiteTemplate
                    )
                  }
                  // className="quote-product-detail-text"
                  // onClick={(e) => handleProductView(item)}
                >
                  {item.productName} {`(${item.productCode})`}
                </span>
              </div>
              <div className="quote-text-wrapper p-t-5 p-b-5">
                <span
                  className={CustomStyles.getStyle(
                    websiteTemplate?.templateCode?.toLowerCase().trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    websiteTemplate
                  )}
                  // className="quote-product-detail-text"
                  // onClick={(e) => handleProductView(item)}
                >
                  {item.productDescription}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>
                  {" "}
                  {IntlUtil.getText(intlNamespace, "content.country") + ": "}
                </span>
                <span className="service-table-lable">{`${item.availabilityName}`}</span>
              </div>
              <div>
                <span>
                  {" "}
                  {IntlUtil.getText(intlNamespace, "content.planOrConnection") +
                    ": "}
                </span>
                {item?.connection && (
                  <span className="service-table-lable">
                    {item?.connection ? item.connection : ""}
                  </span>
                )}
                {!item?.connection && (
                  <span className="service-table-lable">
                    {handleConnectionName(item.planId, item.planTypes)
                      ? handleConnectionName(item.planId, item.planTypes)
                      : ""}
                  </span>
                )}
              </div>
              {item?.communicationPlatform ? (
                <div>
                  <span>
                    {" "}
                    {IntlUtil.getText(intlNamespace, "content.platform") + ": "}
                  </span>
                  <span className="service-table-lable">
                    {item?.communicationPlatform}
                  </span>
                </div>
              ) : null}
              {item.termPlan?.toLowerCase() ===
                OperatorConnectConstants.GENERAL.YES?.toLowerCase() &&
              item.termLink !== null &&
              item.termLink !== "" ? (
                <div className="item-action-term-wrapper">
                  <div>
                    <span className="m-r-5">
                      {IntlUtil.getText(
                        intlNamespace,
                        "content.termsAndConditions"
                      ) + ": "}
                    </span>

                    <span
                      className="quote-view-term-link"
                      onClick={() => handleTermLinkClick(item.termLink)}
                    >
                      {item.termPlan ? (
                        <>
                          <TooltipHost
                            content={IntlUtil.getText(
                              intlNamespace,
                              "content.viewTerms"
                            )}
                          >
                            <span className="m-r-5 service-table-lable">
                              {item.termPlan}
                            </span>
                            <OpenInNewTabIcon className="quote-link-icon" />
                          </TooltipHost>
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="">
                    {item?.termPlan && (
                      <>
                        <span>
                          {IntlUtil.getText(
                            intlNamespace,
                            "content.termsAndConditions"
                          ) + ": "}
                        </span>
                        <span className=" service-table-lable">
                          {item.termPlan}
                        </span>
                      </>
                    )}
                    {!item?.termPlan && " -"}
                  </div>
                </>
              )}
            </div>
          </>
        );
      case "qunatity":
        return (
          <>
            <span>
              {parseInt(item?.quantity)?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </>
        );

      case "chargeName":
        return (
          <>
            <ul className="quote-table-row">
              {item.pricing.length > 0 &&
                item.pricing.map((price) => {
                  return (
                    <>
                      <li className="quote-table-cell">
                        <span>{price.chargeName}</span>
                      </li>
                    </>
                  );
                })}
            </ul>
          </>
        );

      case "chargeType":
        return (
          <>
            <ul className="quote-table-row">
              {item.pricing.length > 0 &&
                item.pricing.map((price) => {
                  return (
                    <>
                      <li className="quote-table-cell">
                        <span>{renderChargeTypeLabel(price.chargeType)}</span>
                      </li>
                    </>
                  );
                })}
            </ul>
          </>
        );

      case "customerPriceUnitPrice":
        return (
          <>
            <ul className="quote-table-row quote-table-unit-price-column">
              {item.pricing.length > 0 &&
                item.pricing.map((price) => {
                  return (
                    <>
                      <li className="quote-table-cell-alt">
                        {price?.discountPercent > 0 &&
                        price?.maximumPrice > 0 ? (
                          <TooltipHost
                            content={`${IntlUtil.getText(
                              intlNamespace,
                              "content.discount"
                            )}: ${price?.discountPercent}%`}
                          >
                            {item?.currencySymbol +
                              parseFloat(price.sellingPrice)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                          </TooltipHost>
                        ) : (
                          <div>
                            {item?.currencySymbol +
                              parseFloat(price.sellingPrice)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                          </div>
                        )}
                      </li>
                    </>
                  );
                })}
            </ul>
          </>
        );
      case "customerPriceAmount":
        return (
          <>
            <ul className="quote-table-row quote-table-customer-total-column">
              {item.pricing.length > 0 &&
                item.pricing.map((price) => {
                  let sellingPrice =
                    parseFloat(item?.quantity ?? "0").toFixed(2) *
                    parseFloat(price.sellingPrice).toFixed(2);
                  return (
                    <>
                      <li className="quote-table-cell-alt">
                        <div>
                          <span className="price-wrapper">
                            {price?.discountPercent > 0 &&
                            price?.maximumPrice > 0 ? (
                              <TooltipHost
                                content={`${IntlUtil.getText(
                                  intlNamespace,
                                  "content.discount"
                                )}: ${price?.discountPercent}%`}
                              >
                                {item?.currencySymbol +
                                  parseFloat(sellingPrice)?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </TooltipHost>
                            ) : (
                              <div>
                                {item?.currencySymbol +
                                  parseFloat(sellingPrice)?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </div>
                            )}
                          </span>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ul>
          </>
        );
      default:
        return <>{item[column.fieldName]}</>;
    }
  };

  const renderQuoteEstimatorTableHeaders = () => {
    let tableHeaders = null;
    if (isEditable === false) {
      tableHeaders = QuoteOrderTableColumns(intlNamespace).filter(
        (rec) => rec.key !== "actions"
      );
    } else {
      tableHeaders = QuoteOrderTableColumns(intlNamespace);
    }
    return tableHeaders;
  };

  return (
    <>
      {serviceRecords?.length > 0 ? (
        <div className="m-8">
          <div className="quote-table-wrapper m-l-8">
            <div className="quote-table">
              <DetailsList
                className={
                  isEditable === false
                    ? "quote-subscriber-table-list"
                    : "quote-subscriber-actions-table-list"
                }
                // onRenderDetailsHeader={renderDetailsListHeader}
                onRenderDetailsFooter={renderDetailsListFooter}
                columns={renderQuoteEstimatorTableHeaders()}
                onRenderRow={renderRow}
                onRenderItemColumn={handleProductItemRender}
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.unconstrained}
                onShouldVirtualize={() => false}
                items={serviceRecords || []}
              />
            </div>
          </div>
          <div
            className={
              isEditable === false
                ? "quote-subscriber-summary-table-wrapper m-b-20 "
                : "quote-subscriber-summary-actions-table-wrapper m-b-20"
            }
          >
            <div className="quote-subscriber-summary-table">
              <div className="quote-summary-table-header">
                <div>{IntlUtil.getText(intlNamespace, "content.summary")}</div>
                <div className="text-align-right m-r-10">
                  {IntlUtil.getText(intlNamespace, "content.amount")}
                </div>
              </div>
              <div className="quote-summary-table-content">
                <div>
                  <span>
                    {" "}
                    {IntlUtil.getText(
                      intlNamespace,
                      "content.nonRecurringCharges"
                    )}
                  </span>
                </div>
                <div className="text-align-right text-break-word m-r-10">
                  {customerNRCprice()}
                </div>
              </div>
              <div className="quote-summary-table-content">
                <div>
                  <span>
                    {" "}
                    {IntlUtil.getText(
                      intlNamespace,
                      "content.recurringCharges"
                    )}
                  </span>
                </div>
                <div className="text-align-right text-break-word m-r-10">
                  {customerMRCprice()}
                </div>
              </div>
              <div className="quote-summary-table-content">
                <div>
                  <span className="text-ff-semibold">
                    {IntlUtil.getText(intlNamespace, "content.total")}
                  </span>
                  &nbsp;
                  <span className="">
                    ({IntlUtil.getText(intlNamespace, "content.taxNotIncluded")}
                    )
                  </span>
                </div>
                <div className="text-ff-semibold text-align-right text-break-word m-r-10">
                  {customerTotalprice()}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
});

const mapActionToProps = {
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(memo(QuoteServiceTable));
